import { Direction, OrderBy } from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const getDate = (date: string | undefined) => {
  if (date) {
    return new Date(date).toISOString();
  }

  return new Date().toISOString();
};

export interface States {
  isShowUtilityDrawer: boolean;

  listOrdersEndCursor: string;
  listOrdersEndDate: string | null;
  listOrdersOrderBy: OrderBy;
  listOrdersPageNumber: number;
  listOrdersPageSize: number;
  listOrdersRowIndex: number;
  listOrdersSearchTerm: string;
  listOrdersStartDate: string | null;
}

const initialState: States = {
  isShowUtilityDrawer: false,

  listOrdersEndCursor: '',
  listOrdersEndDate: getDate('2022-12-01'),
  listOrdersOrderBy: {
    direction: Direction.Desc,
    field: 'date',
  },
  listOrdersPageNumber: 0,
  listOrdersPageSize: 100,
  listOrdersRowIndex: -1,
  listOrdersSearchTerm: '',
  listOrdersStartDate: getDate('2022-09-01'),
};

export const reportListOrdersSlice = createSlice({
  initialState,
  name: 'reportListOrders',
  reducers: {
    setIsShowUtilityDrawer: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowUtilityDrawer = payload;
    },

    setListOrdersEndCursor: (state, { payload }: PayloadAction<string>) => {
      state.listOrdersEndCursor = payload;
    },
    setListOrdersOrderBy: (state, { payload }: PayloadAction<OrderBy>) => {
      state.listOrdersOrderBy = payload;
    },
    setListOrdersPageNumber: (state, { payload }: PayloadAction<number>) => {
      state.listOrdersPageNumber = payload;
    },
    setListOrdersPageSize: (state, { payload }: PayloadAction<number>) => {
      state.listOrdersPageSize = payload;
    },
    setListOrdersRowIndex: (state, { payload }: PayloadAction<number>) => {
      state.listOrdersRowIndex = payload;
    },
    setListOrdersSearchTerm: (state, { payload }: PayloadAction<string>) => {
      state.listOrdersSearchTerm = payload;
    },
  },
});

export default reportListOrdersSlice.reducer;

export const {
  setIsShowUtilityDrawer: setIsShowUtilityDrawerAction,
  setListOrdersEndCursor: setListOrdersEndCursorAction,
  setListOrdersPageNumber: setListOrdersPageNumberAction,
  setListOrdersPageSize: setListOrdersPageSizeAction,
  setListOrdersRowIndex: setListOrdersRowIndexAction,
  setListOrdersSearchTerm: setListOrdersSearchTermAction,
} = reportListOrdersSlice.actions;
