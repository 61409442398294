import { configureStore } from '@reduxjs/toolkit';
import { developerManagementSystemSlice } from 'features/_DeveloperManagementSystem/store';
import { developerUserTelemetrySlice } from 'features/_DeveloperUserTelemetry/store';
import { supportSlice } from 'features/_Support/store';
import { adminsEditAdminSlice } from 'features/Admins/EditAdmin/store';
import { adminsLisAdminsSlice } from 'features/Admins/ListAdmins/store';
import { coachCombinedAvailabilitySlice } from 'features/Bookings/CoachCombinedAvailability/store';
import { bookingsEditBookingSlice } from 'features/Bookings/EditBooking/store';
import { facilityUsageSlice } from 'features/Bookings/FacilityUsage/store';
import { bookingsListBlackoutsSlice } from 'features/Bookings/ListBlackouts/store';
import { bookingsListBookingsActiveSlice } from 'features/Bookings/ListBookingsActive/store';
import { bookingsListBookingsCancelledSlice } from 'features/Bookings/ListBookingsCancelled/store';
import { bookingsNewBookingSlice } from 'features/Bookings/NewBooking/store';
import { clubhousesEditClubhouseSlice } from 'features/Clubhouses/EditClubhouse/store';
import { clubhousesListClubhousesSlice } from 'features/Clubhouses/ListClubhouses/store';
import { clubhouseStaffEditClubhouseStaffSlice } from 'features/ClubhouseStaff/EditClubhouseStaff/store';
import { clubhouseStaffListClubhouseStaffSlice } from 'features/ClubhouseStaff/ListClubhouseStaff/store';
import { coachesEditCoachSlice } from 'features/Coaches/EditCoach/store';
import { coachesListCoachesSlice } from 'features/Coaches/ListCoaches/store';
import { developerSlice } from 'features/Developer/store';
import { membersEditMemberSlice } from 'features/Members/EditMember/store';
import { membersListMembersSlice } from 'features/Members/ListMembers/store';
import { membersListSubscriptionsSlice } from 'features/Members/ListSubscriptions/store';
import { membershipTypesEditMembershipTypeSlice } from 'features/MembershipTypes/EditMembershipType/store';
import { myAccountSlice } from 'features/MyAccount/store';
import { notificationsEditNotificationSlice } from 'features/Notifications/EditNotification/store';
import { notificationsListNotificationsSlice } from 'features/Notifications/ListNotifications/store';
import { notificationsNewNotificationSlice } from 'features/Notifications/NewNotification/store';
import { pricingSlice } from 'features/Pricing/store';
import { productsAddPackageSlice } from 'features/Products/AddPackage/store';
import { productsListProductsSlice } from 'features/Products/ListProducts/store';
import { dataExportSlice } from 'features/Report/DataExport/store';
import { reportEditOrderSlice } from 'features/Report/EditOrder/store';
import { reportListCreditTransactionsSlice } from 'features/Report/ListCreditTransactions/store';
import { reportListHitPayChargesSlice } from 'features/Report/ListHitPayCharges/store';
import { reportListNonConnectedOrdersSlice } from 'features/Report/ListNonConnectedOrders/store';
import { reportListOrdersSlice } from 'features/Report/ListOrders/store';
import { reportListSubscriptionsSlice } from 'features/Report/ListSubscriptions/store';
import { reportMemberStatsSlice } from 'features/Report/MemberStats/store';
import { shopSlice } from 'features/Shop/store';
import { useDispatch } from 'react-redux';
import { authSlice } from './auth';
import { managementSystemSlice } from './management-system';
import { uiSlice } from './ui';

export const store = configureStore({
  devTools: true,
  reducer: {
    adminsEditAdmin: adminsEditAdminSlice.reducer,
    adminsListAdmins: adminsLisAdminsSlice.reducer,
    auth: authSlice.reducer,
    bookingsEditBooking: bookingsEditBookingSlice.reducer,
    bookingsListBlackouts: bookingsListBlackoutsSlice.reducer,
    bookingsListBookingsActive: bookingsListBookingsActiveSlice.reducer,
    bookingsListBookingsCancelled: bookingsListBookingsCancelledSlice.reducer,
    bookingsNewBooking: bookingsNewBookingSlice.reducer,
    clubhouseStaffEditClubhouseStaff:
      clubhouseStaffEditClubhouseStaffSlice.reducer,
    clubhouseStaffListClubhouseStaff:
      clubhouseStaffListClubhouseStaffSlice.reducer,
    clubhousesEditClubhouse: clubhousesEditClubhouseSlice.reducer,
    clubhousesListClubhouses: clubhousesListClubhousesSlice.reducer,
    coachCombinedAvailability: coachCombinedAvailabilitySlice.reducer,
    coachesEditCoach: coachesEditCoachSlice.reducer,
    coachesListCoaches: coachesListCoachesSlice.reducer,
    dataExport: dataExportSlice.reducer,
    developer: developerSlice.reducer,
    developerManagementSystem: developerManagementSystemSlice.reducer,
    developerUserTelemetry: developerUserTelemetrySlice.reducer,
    facilityUsage: facilityUsageSlice.reducer,
    managementSystem: managementSystemSlice.reducer,
    membersEditMember: membersEditMemberSlice.reducer,
    membersListMembers: membersListMembersSlice.reducer,
    membersListSubscriptions: membersListSubscriptionsSlice.reducer,
    membershipTypesEditMembershipType:
      membershipTypesEditMembershipTypeSlice.reducer,
    myAccount: myAccountSlice.reducer,
    notificationsEditNotification: notificationsEditNotificationSlice.reducer,
    notificationsListNotifications: notificationsListNotificationsSlice.reducer,
    notificationsNewNotification: notificationsNewNotificationSlice.reducer,
    pricing: pricingSlice.reducer,
    productsAddPackage: productsAddPackageSlice.reducer,
    productsListProducts: productsListProductsSlice.reducer,
    reportEditOrder: reportEditOrderSlice.reducer,
    reportListCreditTransactions: reportListCreditTransactionsSlice.reducer,
    reportListHitPayCharges: reportListHitPayChargesSlice.reducer,
    reportListNonConnectedOrders: reportListNonConnectedOrdersSlice.reducer,
    reportListOrders: reportListOrdersSlice.reducer,
    reportListSubscriptions: reportListSubscriptionsSlice.reducer,
    reportMemberStats: reportMemberStatsSlice.reducer,
    shop: shopSlice.reducer,
    support: supportSlice.reducer,
    ui: uiSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch();
