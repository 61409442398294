import { Direction, OrderBy } from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface States {
  isShowUtilityDrawer: boolean;

  listSubscriptionsEndCursor: string;
  listSubscriptionsOrderBy: OrderBy;
  listSubscriptionsPageNumber: number;
  listSubscriptionsPageSize: number;
  listSubscriptionsRowIndex: number;
  listSubscriptionsSearchTerm: string;
}

const initialState: States = {
  isShowUtilityDrawer: false,

  listSubscriptionsEndCursor: '',
  listSubscriptionsOrderBy: {
    direction: Direction.Desc,
    field: 'createdAt',
  },
  listSubscriptionsPageNumber: 0,
  listSubscriptionsPageSize: 100,
  listSubscriptionsRowIndex: -1,
  listSubscriptionsSearchTerm: '',
};

export const membersListSubscriptionsSlice = createSlice({
  initialState,
  name: 'membersListSubscriptions',
  reducers: {
    setIsShowUtilityDrawer: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowUtilityDrawer = payload;
    },

    setListSubscriptionsEndCursor: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listSubscriptionsEndCursor = payload;
    },
    setListSubscriptionsOrderBy: (
      state,
      { payload }: PayloadAction<OrderBy>,
    ) => {
      state.listSubscriptionsOrderBy = payload;
    },
    setListSubscriptionsPageNumber: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listSubscriptionsPageNumber = payload;
    },
    setListSubscriptionsPageSize: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listSubscriptionsPageSize = payload;
    },
    setListSubscriptionsRowIndex: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listSubscriptionsRowIndex = payload;
    },
    setListSubscriptionsSearchTerm: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listSubscriptionsSearchTerm = payload;
    },
  },
});

export default membersListSubscriptionsSlice.reducer;

export const {
  setIsShowUtilityDrawer: setIsShowUtilityDrawerAction,
  setListSubscriptionsEndCursor: setListSubscriptionsEndCursorAction,
  setListSubscriptionsPageNumber: setListSubscriptionsPageNumberAction,
  setListSubscriptionsPageSize: setListSubscriptionsPageSizeAction,
  setListSubscriptionsRowIndex: setListSubscriptionsRowIndexAction,
  setListSubscriptionsSearchTerm: setListSubscriptionsSearchTermAction,
} = membersListSubscriptionsSlice.actions;
