import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';
import { useRef } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'store';
import { closeAlertModalAction } from 'store/ui';

interface AlertModalProps {
  alertModal: RootState['ui']['alertModal'];
  closeAlertModal: typeof closeAlertModalAction;
}

const Component = (props: AlertModalProps) => {
  const { alertModal, closeAlertModal } = props;
  const { isOpen, message, title } = alertModal;
  const cancelRef = useRef(null);

  const handleCloseAlertModal = () => {
    closeAlertModal();
  };

  return (
    <AlertDialog
      isCentered
      preserveScrollBarGap
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      variant="primary"
      onClose={handleCloseAlertModal}
    >
      <AlertDialogOverlay backdropFilter="blur(5px)" bg="blackAlpha.300">
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>{message}</AlertDialogBody>

          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              colorScheme="tertiary-action"
              variant="solid"
              onClick={handleCloseAlertModal}
            >
              Close
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

const states = (state: RootState) => ({
  alertModal: state.ui.alertModal,
});

const dispatch = {
  closeAlertModal: closeAlertModalAction,
};

export const GlobalAlertModal = connect(states, dispatch)(Component);
