import { useAdminRefreshAccessTokenMutation } from '@generated/schema';
import loadable from '@loadable/component';
import { Spinner } from 'components/_basic/Spinner';
import { setInitialColorMode } from 'components/ModeSelector';
import { LoginPage } from 'features/Login';
import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'store';
import { loginAction, setAuthStateAction } from 'store/auth';

const App = loadable(() => import('./App'));

interface AuthProps {
  authState: RootState['auth']['authState'];
  login: typeof loginAction;
  setAuthState: typeof setAuthStateAction;
}

function Component(props: AuthProps) {
  const { authState, login, setAuthState } = props;

  const [getToken] = useAdminRefreshAccessTokenMutation();

  useEffect(() => {
    setInitialColorMode();
  }, []);

  const refreshToken = useCallback(async () => {
    try {
      const { data } = await getToken();

      if (data) {
        const { adminRefreshAccessToken } = data;
        const { accessToken, authenticatedAdmin } = adminRefreshAccessToken;

        login({
          accessToken,
          authenticatedAdmin,
        });
      }
    } catch (error) {
      setAuthState('notAuthenticated');
    }
  }, [getToken, login, setAuthState]);

  useEffect(() => {
    refreshToken();
  }, [refreshToken]);

  if (authState === 'loading') {
    return <Spinner justify="screen" />;
  }

  if (authState === 'notAuthenticated') {
    return <LoginPage />;
  }

  if (authState === 'authenticated') {
    return <App />;
  }

  return null;
}

const states = (state: RootState) => ({
  authState: state.auth.authState,
});

const dispatch = {
  login: loginAction,
  setAuthState: setAuthStateAction,
};

export const Auth = connect(states, dispatch)(Component);
