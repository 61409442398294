import { AdminProductEdge, PageInfo } from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SelectedMember = {
  memberIndividualId: string;
  memberName: string;
  memberUuid: string;
  membershipUuid: string;
};

interface States {
  packages: AdminProductEdge[];
  pageInfo: PageInfo | null;
  selectedMember: SelectedMember;
  tabIndex: number;
}

const initialState: States = {
  packages: [],
  pageInfo: null,
  selectedMember: {
    memberIndividualId: '',
    memberName: '',
    memberUuid: '',
    membershipUuid: '',
  },
  tabIndex: 0,
};

export const productsAddPackageSlice = createSlice({
  initialState,
  name: 'productsAddPackage',
  reducers: {
    setPackages: (state, { payload }: PayloadAction<AdminProductEdge[]>) => {
      state.packages = payload;
    },
    setPageInfo: (state, { payload }: PayloadAction<PageInfo>) => {
      state.pageInfo = payload;
    },
    setSelectedMember: (state, { payload }: PayloadAction<SelectedMember>) => {
      state.selectedMember = payload;
    },
    setTabIndex: (state, { payload }: PayloadAction<number>) => {
      state.tabIndex = payload;
    },
  },
});

export default productsAddPackageSlice.reducer;

export const {
  setPackages: setPackagesAction,
  setPageInfo: setPageInfoAction,
  setSelectedMember: setSelectedMemberAction,
  setTabIndex: setTabIndexAction,
} = productsAddPackageSlice.actions;
