import {
  AdminBooking,
  AdminClubhouseFacilityAvailability,
} from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface BookingQuickLook extends AdminBooking {
  isShowModal: boolean;
}

export interface States {
  availability: AdminClubhouseFacilityAvailability | null;
  bookingQuickLook: BookingQuickLook | null;
  clubhouseCode: string;
  currentDate: string | null;
}

const initialState: States = {
  availability: null,
  bookingQuickLook: null,
  clubhouseCode: '',
  currentDate: null,
};

export const facilityUsageSlice = createSlice({
  initialState,
  name: 'facilityUsage',
  reducers: {
    setAvailability: (
      state,
      { payload }: PayloadAction<AdminClubhouseFacilityAvailability | null>,
    ) => {
      state.availability = payload;
    },
    setBookingQuickLook: (
      state,
      { payload }: PayloadAction<BookingQuickLook | null>,
    ) => {
      state.bookingQuickLook = payload;
    },
    setClubhouseCode: (state, { payload }: PayloadAction<string>) => {
      state.clubhouseCode = payload;
    },
    setCurrentDate: (state, { payload }: PayloadAction<string>) => {
      state.currentDate = payload;
    },
  },
});

export default facilityUsageSlice.reducer;

export const {
  setAvailability: setAvailabilityAction,
  setBookingQuickLook: setBookingQuickLookAction,
  setClubhouseCode: setClubhouseCodeAction,
  setCurrentDate: setCurrentDateAction,
} = facilityUsageSlice.actions;
