import { Direction, OrderBy } from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface States {
  listCoachesEndCursor: string;
  listCoachesOrderBy: OrderBy;
  listCoachesPageNumber: number;
  listCoachesPageSize: number;
  listCoachesRowIndex: number;
  listCoachesSearchTerm: string;
}

const initialState: States = {
  listCoachesEndCursor: '',
  listCoachesOrderBy: {
    direction: Direction.Desc,
    field: 'lastAccessTokenDate',
  },
  listCoachesPageNumber: 0,
  listCoachesPageSize: 25,
  listCoachesRowIndex: -1,
  listCoachesSearchTerm: '',
};

export const coachesListCoachesSlice = createSlice({
  initialState,
  name: 'coachesListCoaches',
  reducers: {
    setListCoachesEndCursor: (state, { payload }: PayloadAction<string>) => {
      state.listCoachesEndCursor = payload;
    },
    setListCoachesOrderBy: (state, { payload }: PayloadAction<OrderBy>) => {
      state.listCoachesOrderBy = payload;
    },
    setListCoachesPageNumber: (state, { payload }: PayloadAction<number>) => {
      state.listCoachesPageNumber = payload;
    },
    setListCoachesPageSize: (state, { payload }: PayloadAction<number>) => {
      state.listCoachesPageSize = payload;
    },
    setListCoachesRowIndex: (state, { payload }: PayloadAction<number>) => {
      state.listCoachesRowIndex = payload;
    },
    setListCoachesSearchTerm: (state, { payload }: PayloadAction<string>) => {
      state.listCoachesSearchTerm = payload;
    },
  },
});

export default coachesListCoachesSlice.reducer;

export const {
  setListCoachesEndCursor: setListCoachesEndCursorAction,
  setListCoachesPageNumber: setListCoachesPageNumberAction,
  setListCoachesPageSize: setListCoachesPageSizeAction,
  setListCoachesRowIndex: setListCoachesRowIndexAction,
  setListCoachesSearchTerm: setListCoachesSearchTermAction,
} = coachesListCoachesSlice.actions;
