import { Direction, OrderBy } from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type DateRangeMode = 'today' | 'upcoming' | 'past' | 'all' | 'user';

export interface States {
  dateRangeMode: DateRangeMode;
  isShowUtilityDrawer: boolean;
  listBookingsActiveEndCursor: string;
  listBookingsActiveEndTime: string | null;
  listBookingsActiveOrderBy: OrderBy;
  listBookingsActivePageNumber: number;
  listBookingsActivePageSize: number;
  listBookingsActiveRowIndex: number;
  listBookingsActiveSearchTerm: string;
  listBookingsActiveStartTime: string | null;
}

const initialState: States = {
  dateRangeMode: 'today',
  isShowUtilityDrawer: false,
  listBookingsActiveEndCursor: '',
  listBookingsActiveEndTime: null,
  listBookingsActiveOrderBy: {
    direction: Direction.Asc,
    field: 'startTime',
  },
  listBookingsActivePageNumber: 0,
  listBookingsActivePageSize: 100,
  listBookingsActiveRowIndex: -1,
  listBookingsActiveSearchTerm: '',
  listBookingsActiveStartTime: null,
};

export const bookingsListBookingsActiveSlice = createSlice({
  initialState,
  name: 'bookingsListBookingsActive',
  reducers: {
    setDateRangeMode: (state, { payload }: PayloadAction<DateRangeMode>) => {
      state.dateRangeMode = payload;
    },
    setIsShowUtilityDrawer: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowUtilityDrawer = payload;
    },
    setListBookingsActiveEndCursor: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listBookingsActiveEndCursor = payload;
    },
    setListBookingsActiveEndTime: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listBookingsActiveEndTime = payload;
    },
    setListBookingsActiveOrderBy: (
      state,
      { payload }: PayloadAction<OrderBy>,
    ) => {
      state.listBookingsActiveOrderBy = payload;
    },
    setListBookingsActivePageNumber: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listBookingsActivePageNumber = payload;
    },
    setListBookingsActivePageSize: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listBookingsActivePageSize = payload;
    },
    setListBookingsActiveRowIndex: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listBookingsActiveRowIndex = payload;
    },
    setListBookingsActiveSearchTerm: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listBookingsActiveSearchTerm = payload;
    },
    setListBookingsActiveStartTime: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listBookingsActiveStartTime = payload;
    },
  },
});

export default bookingsListBookingsActiveSlice.reducer;

export const {
  setDateRangeMode: setDateRangeModeAction,
  setIsShowUtilityDrawer: setIsShowUtilityDrawerAction,
  setListBookingsActiveEndCursor: setListBookingsActiveEndCursorAction,
  setListBookingsActiveEndTime: setListBookingsActiveEndTimeAction,
  setListBookingsActiveOrderBy: setListBookingsActiveOrderByAction,
  setListBookingsActivePageNumber: setListBookingsActivePageNumberAction,
  setListBookingsActivePageSize: setListBookingsActivePageSizeAction,
  setListBookingsActiveRowIndex: setListBookingsActiveRowIndexAction,
  setListBookingsActiveSearchTerm: setListBookingsActiveSearchTermAction,
  setListBookingsActiveStartTime: setListBookingsActiveStartTimeAction,
} = bookingsListBookingsActiveSlice.actions;
