import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DrawerTempLinkButtonValue } from 'components/_basic/DrawerComponents';

export interface States {
  selectedBooking: DrawerTempLinkButtonValue;
  tabIndex: number;
}

const initialState: States = {
  selectedBooking: {
    id: '',
    label: '',
  },
  tabIndex: 0,
};

export const bookingsEditBookingSlice = createSlice({
  initialState,
  name: 'bookingsEditBooking',
  reducers: {
    setSelectedBooking: (
      state,
      { payload }: PayloadAction<DrawerTempLinkButtonValue>,
    ) => {
      state.selectedBooking = payload;
    },
    setTabIndex: (state, { payload }: PayloadAction<number>) => {
      state.tabIndex = payload;
    },
  },
});

export default bookingsEditBookingSlice.reducer;

export const {
  setSelectedBooking: setSelectedBookingAction,
  setTabIndex: setTabIndexAction,
} = bookingsEditBookingSlice.actions;
