import {
  AdminBooking,
  BookingStatus,
  CoachAvailabilityCalendarItems,
  CoachClubhouse,
  Direction,
  OrderBy,
} from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const getDate = (date?: string | undefined) => {
  if (date) {
    return new Date(date).toISOString();
  }

  return new Date().toISOString();
};

export interface BookingQuickLook extends AdminBooking {
  isShowModal: boolean;
}

export interface AvailabiltyState {
  bi09: string;
  bi10: string;
  bi11: string;
  bi12: string;
  bi13: string;
  bi14: string;
  bi15: string;
  bi16: string;
  bi17: string;
  bi18: string;
  bi19: string;
  bi20: string;
  bs09: string;
  bs10: string;
  bs11: string;
  bs12: string;
  bs13: string;
  bs14: string;
  bs15: string;
  bs16: string;
  bs17: string;
  bs18: string;
  bs19: string;
  bs20: string;
  date: string;
  l09: string;
  l10: string;
  l11: string;
  l12: string;
  l13: string;
  l14: string;
  l15: string;
  l16: string;
  l17: string;
  l18: string;
  l19: string;
  l20: string;
}

interface InitialLocations {
  l09: string;
  l10: string;
  l11: string;
  l12: string;
  l13: string;
  l14: string;
  l15: string;
  l16: string;
  l17: string;
  l18: string;
  l19: string;
  l20: string;
}

type CoachIDs = {
  coachDisplayName: string;
  coachId: string;
  coachSlug: string;
};

interface SetBookingPayload {
  bookingDescription: string;
  bookingId: string;
  time: string;
}

interface SetClubhousePayload {
  clubhouseCode: string;
  time: string;
}

interface SetOutdoorBookingPayload {
  biOutdoor: string;
  hasOutdoorBooking: boolean;
}

export interface States extends AvailabiltyState {
  availableItems: CoachAvailabilityCalendarItems[];
  availableItemsCurrentDate: string;
  biOutdoor: string;
  bookingQuickLook: BookingQuickLook | null;
  clubhouses: CoachClubhouse[];
  coachDisplayName: string;
  coachId: string;
  coachSlug: string;
  hasOutdoorBooking: boolean;
  initialLocations: InitialLocations;
  listBookingsEndCursor: string;
  listBookingsEndTime: string | null;
  listBookingsOrderBy: OrderBy;
  listBookingsPageNumber: number;
  listBookingsPageSize: number;
  listBookingsRowIndex: number;
  listBookingsSearchTerm: string;
  listBookingsStartTime: string | null;
  listBookingsStatus: BookingStatus;
  locationsHaveChanged: boolean;
  tabIndex: number;
}

const initialState: States = {
  availableItems: [],
  availableItemsCurrentDate: new Date().toDateString(),
  bi09: '',
  bi10: '',
  bi11: '',
  bi12: '',
  bi13: '',
  bi14: '',
  bi15: '',
  bi16: '',
  bi17: '',
  bi18: '',
  bi19: '',
  bi20: '',
  biOutdoor: '',
  bookingQuickLook: null,
  bs09: '',
  bs10: '',
  bs11: '',
  bs12: '',
  bs13: '',
  bs14: '',
  bs15: '',
  bs16: '',
  bs17: '',
  bs18: '',
  bs19: '',
  bs20: '',
  clubhouses: [],
  coachDisplayName: '',
  coachId: '',
  coachSlug: '',
  date: '',
  hasOutdoorBooking: false,
  initialLocations: {
    l09: '',
    l10: '',
    l11: '',
    l12: '',
    l13: '',
    l14: '',
    l15: '',
    l16: '',
    l17: '',
    l18: '',
    l19: '',
    l20: '',
  },
  l09: '',
  l10: '',
  l11: '',
  l12: '',
  l13: '',
  l14: '',
  l15: '',
  l16: '',
  l17: '',
  l18: '',
  l19: '',
  l20: '',
  listBookingsEndCursor: '',
  listBookingsEndTime: null,
  listBookingsOrderBy: {
    direction: Direction.Asc,
    field: 'startTime',
  },
  listBookingsPageNumber: 0,
  listBookingsPageSize: 100,
  listBookingsRowIndex: -1,
  listBookingsSearchTerm: '',
  listBookingsStartTime: getDate(),
  listBookingsStatus: BookingStatus.Active,
  locationsHaveChanged: false,
  tabIndex: 0,
};

export const coachesEditCoachSlice = createSlice({
  initialState,
  name: 'coachesEditCoach',
  reducers: {
    setAvailableItems: (
      state,
      { payload }: PayloadAction<CoachAvailabilityCalendarItems[]>,
    ) => {
      state.availableItems = payload;
    },
    setAvailableItemsCurrentDate: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.availableItemsCurrentDate = payload;
    },
    setBooking: (state, { payload }: PayloadAction<SetBookingPayload>) => {
      const { bookingDescription, bookingId } = payload;
      const dAccessor = `bd${payload.time}` as keyof AvailabiltyState;
      state[dAccessor] = bookingDescription;
      const iAccessor = `bi${payload.time}` as keyof AvailabiltyState;
      state[iAccessor] = bookingId;
    },
    setBookingQuickLook: (
      state,
      { payload }: PayloadAction<BookingQuickLook | null>,
    ) => {
      state.bookingQuickLook = payload;
    },
    setClubhouse: (state, { payload }: PayloadAction<SetClubhousePayload>) => {
      const { clubhouseCode, time } = payload;
      const accessor = `l${time}` as keyof AvailabiltyState;
      state[accessor] = clubhouseCode;
    },
    setClubhouses: (state, { payload }: PayloadAction<CoachClubhouse[]>) => {
      state.clubhouses = payload;
    },
    setCoach: (state, { payload }: PayloadAction<CoachIDs>) => {
      state.coachDisplayName = payload.coachDisplayName;
      state.coachId = payload.coachId;
      state.coachSlug = payload.coachSlug;
    },
    setDate: (state, { payload }: PayloadAction<string>) => {
      state.date = payload;
    },
    setInitialLocations: (
      state,
      { payload }: PayloadAction<InitialLocations>,
    ) => {
      state.initialLocations = payload;
    },
    setListBookingsEndCursor: (state, { payload }: PayloadAction<string>) => {
      state.listBookingsEndCursor = payload;
    },
    setListBookingsEndTime: (
      state,
      { payload }: PayloadAction<string | null>,
    ) => {
      state.listBookingsEndTime = payload;
    },
    setListBookingsOrderBy: (state, { payload }: PayloadAction<OrderBy>) => {
      state.listBookingsOrderBy = payload;
    },
    setListBookingsPageNumber: (state, { payload }: PayloadAction<number>) => {
      state.listBookingsPageNumber = payload;
    },
    setListBookingsPageSize: (state, { payload }: PayloadAction<number>) => {
      state.listBookingsPageSize = payload;
    },
    setListBookingsRowIndex: (state, { payload }: PayloadAction<number>) => {
      state.listBookingsRowIndex = payload;
    },
    setListBookingsStartTime: (
      state,
      { payload }: PayloadAction<string | null>,
    ) => {
      state.listBookingsStartTime = payload;
    },
    setListBookingsStatus: (
      state,
      { payload }: PayloadAction<BookingStatus>,
    ) => {
      state.listBookingsStatus = payload;
    },
    setLocationsHaveChaged: (state, { payload }: PayloadAction<boolean>) => {
      state.locationsHaveChanged = payload;
    },
    setOutdoorBooking: (
      state,
      { payload }: PayloadAction<SetOutdoorBookingPayload>,
    ) => {
      const { biOutdoor, hasOutdoorBooking } = payload;
      state.hasOutdoorBooking = hasOutdoorBooking;
      state.biOutdoor = biOutdoor;
    },
    setTabIndex: (state, { payload }: PayloadAction<number>) => {
      state.tabIndex = payload;
    },
  },
});

export default coachesEditCoachSlice.reducer;

export const {
  setAvailableItems: setAvailableItemsAction,
  setAvailableItemsCurrentDate: setAvailableItemsCurrentDateAction,
  setBooking: setBookingAction,
  setBookingQuickLook: setBookingQuickLookAction,
  setClubhouse: setClubhouseAction,
  setClubhouses: setClubhousesAction,
  setCoach: setCoachAction,
  setDate: setDateAction,
  setInitialLocations: setInitialLocationsAction,
  setListBookingsEndCursor: setListBookingsEndCursorAction,
  setListBookingsEndTime: setListBookingsEndTimeAction,
  setListBookingsOrderBy: setListBookingsOrderByAction,
  setListBookingsPageNumber: setListBookingsPageNumberAction,
  setListBookingsPageSize: setListBookingsPageSizeAction,
  setListBookingsRowIndex: setListBookingsRowIndexAction,
  setListBookingsStartTime: setListBookingsStartTimeAction,
  setListBookingsStatus: setListBookingsStatusAction,
  setLocationsHaveChaged: setLocationsHaveChagedAction,
  setOutdoorBooking: setOutdoorBookingAction,
  setTabIndex: setTabIndexAction,
} = coachesEditCoachSlice.actions;
