import { Direction, OrderBy } from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface States {
  listClubhouseStaffEndCursor: string;
  listClubhouseStaffOrderBy: OrderBy;
  listClubhouseStaffPageNumber: number;
  listClubhouseStaffPageSize: number;
  listClubhouseStaffRowIndex: number;
  listClubhouseStaffSearchTerm: string;
}

const initialState: States = {
  listClubhouseStaffEndCursor: '',
  listClubhouseStaffOrderBy: {
    direction: Direction.Desc,
    field: 'lastAccessTokenDate',
  },
  listClubhouseStaffPageNumber: 0,
  listClubhouseStaffPageSize: 25,
  listClubhouseStaffRowIndex: -1,
  listClubhouseStaffSearchTerm: '',
};

export const clubhouseStaffListClubhouseStaffSlice = createSlice({
  initialState,
  name: 'clubhouseStaffListClubhouseStaff',
  reducers: {
    setListClubhouseStaffEndCursor: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listClubhouseStaffEndCursor = payload;
    },
    setListClubhouseStaffOrderBy: (
      state,
      { payload }: PayloadAction<OrderBy>,
    ) => {
      state.listClubhouseStaffOrderBy = payload;
    },
    setListClubhouseStaffPageNumber: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listClubhouseStaffPageNumber = payload;
    },
    setListClubhouseStaffPageSize: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listClubhouseStaffPageSize = payload;
    },
    setListClubhouseStaffRowIndex: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listClubhouseStaffRowIndex = payload;
    },
    setListClubhouseStaffSearchTerm: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listClubhouseStaffSearchTerm = payload;
    },
  },
});

export default clubhouseStaffListClubhouseStaffSlice.reducer;

export const {
  setListClubhouseStaffEndCursor: setListClubhouseStaffEndCursorAction,
  setListClubhouseStaffPageNumber: setListClubhouseStaffPageNumberAction,
  setListClubhouseStaffPageSize: setListClubhouseStaffPageSizeAction,
  setListClubhouseStaffRowIndex: setListClubhouseStaffRowIndexAction,
  setListClubhouseStaffSearchTerm: setListClubhouseStaffSearchTermAction,
} = clubhouseStaffListClubhouseStaffSlice.actions;
