import { Direction, OrderBy } from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const getDate = (date: string | undefined) => {
  if (date) {
    return new Date(date).toISOString();
  }

  return new Date().toISOString();
};

export interface States {
  isShowUtilityDrawer: boolean;

  listCreditTransactionsEndCursor: string;
  listCreditTransactionsEndDate: string | null;
  listCreditTransactionsOrderBy: OrderBy;
  listCreditTransactionsPageNumber: number;
  listCreditTransactionsPageSize: number;
  listCreditTransactionsRowIndex: number;
  listCreditTransactionsSearchTerm: string;
  listCreditTransactionsStartDate: string | null;
}

const initialState: States = {
  isShowUtilityDrawer: false,

  listCreditTransactionsEndCursor: '',
  listCreditTransactionsEndDate: getDate('2022-12-01'),
  listCreditTransactionsOrderBy: {
    direction: Direction.Desc,
    field: 'createdAt',
  },
  listCreditTransactionsPageNumber: 0,
  listCreditTransactionsPageSize: 100,
  listCreditTransactionsRowIndex: -1,
  listCreditTransactionsSearchTerm: '',
  listCreditTransactionsStartDate: getDate('2022-09-01'),
};

export const reportListCreditTransactionsSlice = createSlice({
  initialState,
  name: 'reportListCreditTransactions',
  reducers: {
    setIsShowUtilityDrawer: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowUtilityDrawer = payload;
    },

    setListCreditTransactionsEndCursor: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listCreditTransactionsEndCursor = payload;
    },
    setListCreditTransactionsOrderBy: (
      state,
      { payload }: PayloadAction<OrderBy>,
    ) => {
      state.listCreditTransactionsOrderBy = payload;
    },
    setListCreditTransactionsPageNumber: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listCreditTransactionsPageNumber = payload;
    },
    setListCreditTransactionsPageSize: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listCreditTransactionsPageSize = payload;
    },
    setListCreditTransactionsRowIndex: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listCreditTransactionsRowIndex = payload;
    },
    setListCreditTransactionsSearchTerm: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listCreditTransactionsSearchTerm = payload;
    },
  },
});

export default reportListCreditTransactionsSlice.reducer;

export const {
  setIsShowUtilityDrawer: setIsShowUtilityDrawerAction,
  setListCreditTransactionsEndCursor: setListCreditTransactionsEndCursorAction,
  setListCreditTransactionsPageNumber:
    setListCreditTransactionsPageNumberAction,
  setListCreditTransactionsPageSize: setListCreditTransactionsPageSizeAction,
  setListCreditTransactionsRowIndex: setListCreditTransactionsRowIndexAction,
  setListCreditTransactionsSearchTerm:
    setListCreditTransactionsSearchTermAction,
} = reportListCreditTransactionsSlice.actions;
