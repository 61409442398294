import {
  AdminMemberActivationLineItem,
  BookingStatus,
  Direction,
  OrderBy,
} from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DrawerTempLinkButtonValue } from 'components/_basic/DrawerComponents';

const getDate = (date?: string | undefined) => {
  if (date) {
    return new Date(date).toISOString();
  }

  return new Date().toISOString();
};

export interface States {
  activationLineItems: AdminMemberActivationLineItem[];
  activationOrderNo: string;
  activationPrice: number;
  checkStatus: boolean;
  isShowNotificationDrawer: boolean;
  isShowPaymentDrawer: boolean;
  isShowTierUpdateDrawer: boolean;
  isShowUtilityDrawer: boolean;

  listBookingsEndCursor: string;
  listBookingsEndTime: string | null;
  listBookingsOrderBy: OrderBy;
  listBookingsPageNumber: number;
  listBookingsPageSize: number;
  listBookingsRowIndex: number;
  listBookingsSearchTerm: string;
  listBookingsStartTime: string | null;
  listBookingsStatus: BookingStatus;

  listCreditTransactionsEndCursor: string;
  listCreditTransactionsEndDate: string | null;
  listCreditTransactionsOrderBy: OrderBy;
  listCreditTransactionsPageNumber: number;
  listCreditTransactionsPageSize: number;
  listCreditTransactionsRowIndex: number;
  listCreditTransactionsSearchTerm: string;
  listCreditTransactionsStartDate: string | null;

  listGameCreditTransactionsEndCursor: string;
  listGameCreditTransactionsEndDate: string | null;
  listGameCreditTransactionsOrderBy: OrderBy;
  listGameCreditTransactionsPageNumber: number;
  listGameCreditTransactionsPageSize: number;
  listGameCreditTransactionsRowIndex: number;
  listGameCreditTransactionsSearchTerm: string;
  listGameCreditTransactionsStartDate: string | null;

  listLessonVouchersEndCursor: string;
  listLessonVouchersEndDate: string | null;
  listLessonVouchersOrderBy: OrderBy;
  listLessonVouchersPageNumber: number;
  listLessonVouchersPageSize: number;
  listLessonVouchersRowIndex: number;
  listLessonVouchersSearchTerm: string;
  listLessonVouchersStartDate: string | null;

  listNotificationsEndCursor: string;
  listNotificationsEndDate: string | null;
  listNotificationsOrderBy: OrderBy;
  listNotificationsPageNumber: number;
  listNotificationsPageSize: number;
  listNotificationsRowIndex: number;
  listNotificationsSearchTerm: string;
  listNotificationsStartDate: string | null;

  listPaymentsEndCursor: string;
  listPaymentsEndDate: string | null;
  listPaymentsOrderBy: OrderBy;
  listPaymentsPageNumber: number;
  listPaymentsPageSize: number;
  listPaymentsRowIndex: number;
  listPaymentsSearchTerm: string;
  listPaymentsStartDate: string | null;

  listPractiseCreditTransactionsEndCursor: string;
  listPractiseCreditTransactionsEndDate: string | null;
  listPractiseCreditTransactionsOrderBy: OrderBy;
  listPractiseCreditTransactionsPageNumber: number;
  listPractiseCreditTransactionsPageSize: number;
  listPractiseCreditTransactionsRowIndex: number;
  listPractiseCreditTransactionsSearchTerm: string;
  listPractiseCreditTransactionsStartDate: string | null;

  listServiceTransactionsEndCursor: string;
  listServiceTransactionsEndDate: string | null;
  listServiceTransactionsOrderBy: OrderBy;
  listServiceTransactionsPageNumber: number;
  listServiceTransactionsPageSize: number;
  listServiceTransactionsRowIndex: number;
  listServiceTransactionsSearchTerm: string;
  listServiceTransactionsStartDate: string | null;

  listSubscriptionsEndCursor: string;
  listSubscriptionsEndDate: string | null;
  listSubscriptionsOrderBy: OrderBy;
  listSubscriptionsPageNumber: number;
  listSubscriptionsPageSize: number;
  listSubscriptionsRowIndex: number;
  listSubscriptionsSearchTerm: string;
  listSubscriptionsStartDate: string | null;

  memberIndividualId: string;
  memberName: string;
  memberUuid: string;
  membershipUuid: string;
  selectedMember: DrawerTempLinkButtonValue;
  tabIndex: number;
}

const initialState: States = {
  activationLineItems: [],
  activationOrderNo: '',
  activationPrice: 0,
  checkStatus: false,
  isShowNotificationDrawer: false,
  isShowPaymentDrawer: false,
  isShowTierUpdateDrawer: false,
  isShowUtilityDrawer: false,

  listBookingsEndCursor: '',
  listBookingsEndTime: null,
  listBookingsOrderBy: {
    direction: Direction.Asc,
    field: 'startTime',
  },
  listBookingsPageNumber: 0,
  listBookingsPageSize: 100,
  listBookingsRowIndex: -1,
  listBookingsSearchTerm: '',
  listBookingsStartTime: getDate(),
  listBookingsStatus: BookingStatus.Active,

  listCreditTransactionsEndCursor: '',
  listCreditTransactionsEndDate: null,
  listCreditTransactionsOrderBy: {
    direction: Direction.Desc,
    field: 'createdAt',
  },
  listCreditTransactionsPageNumber: 0,
  listCreditTransactionsPageSize: 100,
  listCreditTransactionsRowIndex: -1,
  listCreditTransactionsSearchTerm: '',
  listCreditTransactionsStartDate: null,

  listGameCreditTransactionsEndCursor: '',
  listGameCreditTransactionsEndDate: null,
  listGameCreditTransactionsOrderBy: {
    direction: Direction.Desc,
    field: 'createdAt',
  },
  listGameCreditTransactionsPageNumber: 0,
  listGameCreditTransactionsPageSize: 100,
  listGameCreditTransactionsRowIndex: -1,
  listGameCreditTransactionsSearchTerm: '',
  listGameCreditTransactionsStartDate: null,

  listLessonVouchersEndCursor: '',
  listLessonVouchersEndDate: null,
  listLessonVouchersOrderBy: {
    direction: Direction.Desc,
    field: 'createdAt',
  },
  listLessonVouchersPageNumber: 0,
  listLessonVouchersPageSize: 100,
  listLessonVouchersRowIndex: -1,
  listLessonVouchersSearchTerm: '',
  listLessonVouchersStartDate: null,

  listNotificationsEndCursor: '',
  listNotificationsEndDate: null,
  listNotificationsOrderBy: {
    direction: Direction.Desc,
    field: 'createdAt',
  },
  listNotificationsPageNumber: 0,
  listNotificationsPageSize: 10,
  listNotificationsRowIndex: -1,
  listNotificationsSearchTerm: '',
  listNotificationsStartDate: null,

  listPaymentsEndCursor: '',
  listPaymentsEndDate: null,
  listPaymentsOrderBy: {
    direction: Direction.Desc,
    field: 'date',
  },
  listPaymentsPageNumber: 0,
  listPaymentsPageSize: 100,
  listPaymentsRowIndex: -1,
  listPaymentsSearchTerm: '',
  listPaymentsStartDate: null,

  listPractiseCreditTransactionsEndCursor: '',
  listPractiseCreditTransactionsEndDate: null,
  listPractiseCreditTransactionsOrderBy: {
    direction: Direction.Desc,
    field: 'createdAt',
  },
  listPractiseCreditTransactionsPageNumber: 0,
  listPractiseCreditTransactionsPageSize: 100,
  listPractiseCreditTransactionsRowIndex: -1,
  listPractiseCreditTransactionsSearchTerm: '',
  listPractiseCreditTransactionsStartDate: null,

  listServiceTransactionsEndCursor: '',
  listServiceTransactionsEndDate: null,
  listServiceTransactionsOrderBy: {
    direction: Direction.Desc,
    field: 'date',
  },
  listServiceTransactionsPageNumber: 0,
  listServiceTransactionsPageSize: 100,
  listServiceTransactionsRowIndex: -1,
  listServiceTransactionsSearchTerm: '',
  listServiceTransactionsStartDate: null,

  listSubscriptionsEndCursor: '',
  listSubscriptionsEndDate: null,
  listSubscriptionsOrderBy: {
    direction: Direction.Desc,
    field: 'endDate',
  },
  listSubscriptionsPageNumber: 0,
  listSubscriptionsPageSize: 100,
  listSubscriptionsRowIndex: -1,
  listSubscriptionsSearchTerm: '',
  listSubscriptionsStartDate: null,

  memberIndividualId: '',
  memberName: '',
  memberUuid: '',
  membershipUuid: '',
  selectedMember: {
    id: '',
    label: '',
  },
  tabIndex: 0,
};

type MemberIDs = {
  memberIndividualId: string;
  memberName: string;
  memberUuid: string;
  membershipUuid: string;
};

export const membersEditMemberSlice = createSlice({
  initialState,
  name: 'membersEditMember',
  reducers: {
    setActivationLineItems: (
      state,
      { payload }: PayloadAction<AdminMemberActivationLineItem[]>,
    ) => {
      state.activationLineItems = payload;
    },
    setActivationOrderNo: (state, { payload }: PayloadAction<string>) => {
      state.activationOrderNo = payload;
    },
    setActivationPrice: (state, { payload }: PayloadAction<number>) => {
      state.activationPrice = payload;
    },
    setCheckStatus: (state, { payload }: PayloadAction<boolean>) => {
      state.checkStatus = payload;
    },
    setIsShowNotificationDrawer: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isShowNotificationDrawer = payload;
    },
    setIsShowPaymentDrawer: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowPaymentDrawer = payload;
    },
    setIsShowTierUpdateDrawer: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowTierUpdateDrawer = payload;
    },
    setIsShowUtilityDrawer: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowUtilityDrawer = payload;
    },

    setListBookingsEndCursor: (state, { payload }: PayloadAction<string>) => {
      state.listBookingsEndCursor = payload;
    },
    setListBookingsEndTime: (
      state,
      { payload }: PayloadAction<string | null>,
    ) => {
      state.listBookingsEndTime = payload;
    },
    setListBookingsOrderBy: (state, { payload }: PayloadAction<OrderBy>) => {
      state.listBookingsOrderBy = payload;
    },
    setListBookingsPageNumber: (state, { payload }: PayloadAction<number>) => {
      state.listBookingsPageNumber = payload;
    },
    setListBookingsPageSize: (state, { payload }: PayloadAction<number>) => {
      state.listBookingsPageSize = payload;
    },
    setListBookingsRowIndex: (state, { payload }: PayloadAction<number>) => {
      state.listBookingsRowIndex = payload;
    },
    setListBookingsStartTime: (
      state,
      { payload }: PayloadAction<string | null>,
    ) => {
      state.listBookingsStartTime = payload;
    },
    setListBookingsStatus: (
      state,
      { payload }: PayloadAction<BookingStatus>,
    ) => {
      state.listBookingsStatus = payload;
    },

    setListCreditTransactionsEndCursor: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listCreditTransactionsEndCursor = payload;
    },
    setListCreditTransactionsOrderBy: (
      state,
      { payload }: PayloadAction<OrderBy>,
    ) => {
      state.listCreditTransactionsOrderBy = payload;
    },
    setListCreditTransactionsPageNumber: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listCreditTransactionsPageNumber = payload;
    },
    setListCreditTransactionsPageSize: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listCreditTransactionsPageSize = payload;
    },
    setListCreditTransactionsRowIndex: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listCreditTransactionsRowIndex = payload;
    },
    setListCreditTransactionsSearchTerm: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listCreditTransactionsSearchTerm = payload;
    },

    setListGameCreditTransactionsEndCursor: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listGameCreditTransactionsEndCursor = payload;
    },
    setListGameCreditTransactionsOrderBy: (
      state,
      { payload }: PayloadAction<OrderBy>,
    ) => {
      state.listGameCreditTransactionsOrderBy = payload;
    },
    setListGameCreditTransactionsPageNumber: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listGameCreditTransactionsPageNumber = payload;
    },
    setListGameCreditTransactionsPageSize: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listGameCreditTransactionsPageSize = payload;
    },
    setListGameCreditTransactionsRowIndex: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listGameCreditTransactionsRowIndex = payload;
    },
    setListGameCreditTransactionsSearchTerm: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listGameCreditTransactionsSearchTerm = payload;
    },

    setListLessonVouchersEndCursor: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listLessonVouchersEndCursor = payload;
    },
    setListLessonVouchersOrderBy: (
      state,
      { payload }: PayloadAction<OrderBy>,
    ) => {
      state.listLessonVouchersOrderBy = payload;
    },
    setListLessonVouchersPageNumber: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listLessonVouchersPageNumber = payload;
    },
    setListLessonVouchersPageSize: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listLessonVouchersPageSize = payload;
    },
    setListLessonVouchersRowIndex: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listLessonVouchersRowIndex = payload;
    },
    setListLessonVouchersSearchTerm: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listLessonVouchersSearchTerm = payload;
    },

    setListNotificationsEndCursor: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listNotificationsEndCursor = payload;
    },
    setListNotificationsOrderBy: (
      state,
      { payload }: PayloadAction<OrderBy>,
    ) => {
      state.listNotificationsOrderBy = payload;
    },
    setListNotificationsPageNumber: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listNotificationsPageNumber = payload;
    },
    setListNotificationsPageSize: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listNotificationsPageSize = payload;
    },
    setListNotificationsRowIndex: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listNotificationsRowIndex = payload;
    },
    setListNotificationsSearchTerm: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listNotificationsSearchTerm = payload;
    },

    setListPaymentsEndCursor: (state, { payload }: PayloadAction<string>) => {
      state.listPaymentsEndCursor = payload;
    },
    setListPaymentsOrderBy: (state, { payload }: PayloadAction<OrderBy>) => {
      state.listPaymentsOrderBy = payload;
    },
    setListPaymentsPageNumber: (state, { payload }: PayloadAction<number>) => {
      state.listPaymentsPageNumber = payload;
    },
    setListPaymentsPageSize: (state, { payload }: PayloadAction<number>) => {
      state.listPaymentsPageSize = payload;
    },

    setListPaymentsRowIndex: (state, { payload }: PayloadAction<number>) => {
      state.listPaymentsRowIndex = payload;
    },
    setListPaymentsSearchTerm: (state, { payload }: PayloadAction<string>) => {
      state.listPaymentsSearchTerm = payload;
    },

    setListPractiseCreditTransactionsEndCursor: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listPractiseCreditTransactionsEndCursor = payload;
    },
    setListPractiseCreditTransactionsOrderBy: (
      state,
      { payload }: PayloadAction<OrderBy>,
    ) => {
      state.listPractiseCreditTransactionsOrderBy = payload;
    },
    setListPractiseCreditTransactionsPageNumber: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listPractiseCreditTransactionsPageNumber = payload;
    },
    setListPractiseCreditTransactionsPageSize: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listPractiseCreditTransactionsPageSize = payload;
    },
    setListPractiseCreditTransactionsRowIndex: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listPractiseCreditTransactionsRowIndex = payload;
    },
    setListPractiseCreditTransactionsSearchTerm: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listPractiseCreditTransactionsSearchTerm = payload;
    },

    setListServiceTransactionsEndCursor: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listServiceTransactionsEndCursor = payload;
    },
    setListServiceTransactionsOrderBy: (
      state,
      { payload }: PayloadAction<OrderBy>,
    ) => {
      state.listServiceTransactionsOrderBy = payload;
    },
    setListServiceTransactionsPageNumber: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listServiceTransactionsPageNumber = payload;
    },
    setListServiceTransactionsPageSize: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listServiceTransactionsPageSize = payload;
    },
    setListServiceTransactionsRowIndex: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listServiceTransactionsRowIndex = payload;
    },
    setListServiceTransactionsSearchTerm: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listServiceTransactionsSearchTerm = payload;
    },

    setListSubscriptionsEndCursor: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listSubscriptionsEndCursor = payload;
    },
    setListSubscriptionsOrderBy: (
      state,
      { payload }: PayloadAction<OrderBy>,
    ) => {
      state.listSubscriptionsOrderBy = payload;
    },
    setListSubscriptionsPageNumber: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listSubscriptionsPageNumber = payload;
    },
    setListSubscriptionsPageSize: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listSubscriptionsPageSize = payload;
    },
    setListSubscriptionsRowIndex: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listSubscriptionsRowIndex = payload;
    },
    setListSubscriptionsSearchTerm: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listSubscriptionsSearchTerm = payload;
    },

    setMemberIDs: (state, { payload }: PayloadAction<MemberIDs>) => {
      state.memberUuid = payload.memberUuid;
      state.membershipUuid = payload.membershipUuid;
      state.memberName = payload.memberName;
      state.memberIndividualId = payload.memberIndividualId;
    },
    setSelectedMember: (
      state,
      { payload }: PayloadAction<DrawerTempLinkButtonValue>,
    ) => {
      state.selectedMember = payload;
    },

    setTabIndex: (state, { payload }: PayloadAction<number>) => {
      state.tabIndex = payload;
    },
  },
});

export default membersEditMemberSlice.reducer;

export const {
  setActivationLineItems: setActivationLineItemsAction,
  setActivationOrderNo: setActivationOrderNoAction,
  setActivationPrice: setActivationPriceAction,
  setCheckStatus: setCheckStatusAction,
  setIsShowNotificationDrawer: setIsShowNotificationDrawerAction,
  setIsShowPaymentDrawer: setIsShowPaymentDrawerAction,
  setIsShowTierUpdateDrawer: setIsShowTierUpdateDrawerAction,
  setIsShowUtilityDrawer: setIsShowUtilityDrawerAction,

  setListBookingsEndCursor: setListBookingsEndCursorAction,
  setListBookingsEndTime: setListBookingsEndTimeAction,
  setListBookingsOrderBy: setListBookingsOrderByAction,
  setListBookingsPageNumber: setListBookingsPageNumberAction,
  setListBookingsPageSize: setListBookingsPageSizeAction,
  setListBookingsRowIndex: setListBookingsRowIndexAction,
  setListBookingsStartTime: setListBookingsStartTimeAction,
  setListBookingsStatus: setListBookingsStatusAction,
  setListCreditTransactionsEndCursor: setListCreditTransactionsEndCursorAction,
  setListCreditTransactionsPageNumber:
    setListCreditTransactionsPageNumberAction,
  setListCreditTransactionsPageSize: setListCreditTransactionsPageSizeAction,
  setListCreditTransactionsRowIndex: setListCreditTransactionsRowIndexAction,
  setListCreditTransactionsSearchTerm:
    setListCreditTransactionsSearchTermAction,
  setListGameCreditTransactionsEndCursor:
    setListGameCreditTransactionsEndCursorAction,
  setListGameCreditTransactionsPageNumber:
    setListGameCreditTransactionsPageNumberAction,
  setListGameCreditTransactionsPageSize:
    setListGameCreditTransactionsPageSizeAction,
  setListGameCreditTransactionsRowIndex:
    setListGameCreditTransactionsRowIndexAction,
  setListGameCreditTransactionsSearchTerm:
    setListGameCreditTransactionsSearchTermAction,
  setListLessonVouchersEndCursor: setListLessonVouchersEndCursorAction,
  setListLessonVouchersPageNumber: setListLessonVouchersPageNumberAction,
  setListLessonVouchersPageSize: setListLessonVouchersPageSizeAction,
  setListLessonVouchersRowIndex: setListLessonVouchersRowIndexAction,
  setListLessonVouchersSearchTerm: setListLessonVouchersSearchTermAction,
  setListNotificationsEndCursor: setListNotificationsEndCursorAction,
  setListNotificationsPageNumber: setListNotificationsPageNumberAction,
  setListNotificationsPageSize: setListNotificationsPageSizeAction,
  setListNotificationsRowIndex: setListNotificationsRowIndexAction,
  setListNotificationsSearchTerm: setListNotificationsSearchTermAction,
  setListPaymentsEndCursor: setListPaymentsEndCursorAction,
  setListPaymentsPageNumber: setListPaymentsPageNumberAction,
  setListPaymentsPageSize: setListPaymentsPageSizeAction,
  setListPaymentsRowIndex: setListPaymentsRowIndexAction,
  setListPaymentsSearchTerm: setListPaymentsSearchTermAction,
  setListPractiseCreditTransactionsEndCursor:
    setListPractiseCreditTransactionsEndCursorAction,
  setListPractiseCreditTransactionsPageNumber:
    setListPractiseCreditTransactionsPageNumberAction,
  setListPractiseCreditTransactionsPageSize:
    setListPractiseCreditTransactionsPageSizeAction,
  setListPractiseCreditTransactionsRowIndex:
    setListPractiseCreditTransactionsRowIndexAction,
  setListPractiseCreditTransactionsSearchTerm:
    setListPractiseCreditTransactionsSearchTermAction,

  setListServiceTransactionsEndCursor:
    setListServiceTransactionsEndCursorAction,
  setListServiceTransactionsPageNumber:
    setListServiceTransactionsPageNumberAction,
  setListServiceTransactionsPageSize: setListServiceTransactionsPageSizeAction,
  setListServiceTransactionsRowIndex: setListServiceTransactionsRowIndexAction,
  setListServiceTransactionsSearchTerm:
    setListServiceTransactionsSearchTermAction,

  setListSubscriptionsEndCursor: setListSubscriptionsEndCursorAction,
  setListSubscriptionsPageNumber: setListSubscriptionsPageNumberAction,
  setListSubscriptionsPageSize: setListSubscriptionsPageSizeAction,

  setListSubscriptionsRowIndex: setListSubscriptionsRowIndexAction,
  setListSubscriptionsSearchTerm: setListSubscriptionsSearchTermAction,
  setMemberIDs: setMemberIDsAction,
  setSelectedMember: setSelectedMemberAction,
  setTabIndex: setTabIndexAction,
} = membersEditMemberSlice.actions;
