import { Direction, OrderBy } from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const getDate = (date: string | undefined) => {
  if (date) {
    return new Date(date).toISOString();
  }

  return new Date().toISOString();
};

export interface States {
  isShowUtilityDrawer: boolean;

  listHitPayChargesEndCursor: string;
  listHitPayChargesEndDate: string | null;
  listHitPayChargesOrderBy: OrderBy;
  listHitPayChargesPageNumber: number;
  listHitPayChargesPageSize: number;
  listHitPayChargesRowIndex: number;
  listHitPayChargesSearchTerm: string;
  listHitPayChargesStartDate: string | null;
}

const initialState: States = {
  isShowUtilityDrawer: false,

  listHitPayChargesEndCursor: '',
  listHitPayChargesEndDate: getDate('2022-12-01'),
  listHitPayChargesOrderBy: {
    direction: Direction.Desc,
    field: 'date',
  },
  listHitPayChargesPageNumber: 0,
  listHitPayChargesPageSize: 100,
  listHitPayChargesRowIndex: -1,
  listHitPayChargesSearchTerm: '',
  listHitPayChargesStartDate: getDate('2022-09-01'),
};

export const reportListHitPayChargesSlice = createSlice({
  initialState,
  name: 'reportListHitPayCharges',
  reducers: {
    setIsShowUtilityDrawer: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowUtilityDrawer = payload;
    },

    setListHitPayChargesEndCursor: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listHitPayChargesEndCursor = payload;
    },
    setListHitPayChargesOrderBy: (
      state,
      { payload }: PayloadAction<OrderBy>,
    ) => {
      state.listHitPayChargesOrderBy = payload;
    },
    setListHitPayChargesPageNumber: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listHitPayChargesPageNumber = payload;
    },
    setListHitPayChargesPageSize: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listHitPayChargesPageSize = payload;
    },
    setListHitPayChargesRowIndex: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listHitPayChargesRowIndex = payload;
    },
    setListHitPayChargesSearchTerm: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listHitPayChargesSearchTerm = payload;
    },
  },
});

export default reportListHitPayChargesSlice.reducer;

export const {
  setIsShowUtilityDrawer: setIsShowUtilityDrawerAction,
  setListHitPayChargesEndCursor: setListHitPayChargesEndCursorAction,
  setListHitPayChargesPageNumber: setListHitPayChargesPageNumberAction,
  setListHitPayChargesPageSize: setListHitPayChargesPageSizeAction,
  setListHitPayChargesRowIndex: setListHitPayChargesRowIndexAction,
  setListHitPayChargesSearchTerm: setListHitPayChargesSearchTermAction,
} = reportListHitPayChargesSlice.actions;
